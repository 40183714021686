import React from 'react';
import styled from 'styled-components';
import Img from 'ground-web/components/v1/Img';
import SEO from '../components/seo';
import Ul from '../components/directorsCards';
import PageTitle from '../components/page-title';
import Article from '../components/article';
import { decodeHtml } from '../helpers';

const Figure = styled.figure`
  text-align: center;
  margin-bottom: 1.2rem;

  div {
    border-radius: 100%;
    overflow: hidden;
    max-width: 10rem;
    margin: 0 auto;
    margin-bottom: 1.2rem;
    height: 10rem;
    width: 10rem;

    img {
      height: 10rem;
      width: initial;
      max-width: none;
    }
  }
`;

export default function Diretoria(props) {
  const { pageContext } = props;
  const {
    title,
    camposDiretoria: {
      nomeDoPresidente,
      fotoDoPresidente,
      ouvidoria,
      vicePresidencias,
      conselhoGestao,
      assessores,
    },
  } = pageContext.page;

  return (
    <>
      <SEO title={decodeHtml(title)} />
      <Article>
        <PageTitle>{decodeHtml(title)}</PageTitle>

        <Figure>
          <div>
            <Img
              src={fotoDoPresidente.sourceUrl}
              alt="Foto do presidente do clube"
            />
          </div>
          <h4>
            <figcaption>{decodeHtml(nomeDoPresidente)}</figcaption>
          </h4>
          <span>Presidente</span>
        </Figure>
        <Ul role="list" ariaLabel="conselho de gestão">
          {conselhoGestao.map((advisor, index) => (
            <li key={index}>
              <h4>{decodeHtml(advisor.nomeVp)}</h4>
              <span>{decodeHtml(advisor.tituloVp)}</span>
            </li>
          ))}
        </Ul>

        <h3>Vice-Presidências</h3>
        <Ul role="list" ariaLabel="vice-presidentes">
          {vicePresidencias.map((vicePresident, index) => (
            <li key={index}>
              <h4>{decodeHtml(vicePresident.nomeResp)}</h4>
              <span>{decodeHtml(vicePresident.tituloDaVp)}</span>
            </li>
          ))}
        </Ul>

        <h3>Ouvidoria</h3>
        <Ul role="list" ariaLabel="ouvidoria">
          {ouvidoria.map((ombudsman, index) => (
            <li key={index}>
              <h4>{decodeHtml(ombudsman.nomeDoOuvidor)}</h4>
              <span>{decodeHtml(ombudsman.cargoDoOuvidor)}</span>
            </li>
          ))}
        </Ul>
        {assessores && assessores.length > 0 && (
          <div>
            <h3>Assessores da Presidência</h3>
            <Ul role="list" ariaLabel="assessores da presidencia">
              {assessores?.map((assessor, index) => (
                <li key={index}>
                  <h4>{decodeHtml(assessor.nomeDoAssessor)}</h4>
                </li>
              ))}
            </Ul>
          </div>
        )}
      </Article>
    </>
  );
}
